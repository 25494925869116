<template>
  <div id="subpage-content">
    <CompanyDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ storageItemName() }}</h1>
        <div id="about-text-work">
          <img width="220" height="250" id="workpic" :src="storageImageUrl()" />
          <span id="pad" v-html="storageItemText()"></span>
          <div id="pad">
            <v-icon small>mdi-email</v-icon>
            <a id="pad-left">{{ storageItemEmail() }}</a>
          </div>
          <div id="pad">
            <v-icon small>mdi-tag</v-icon>
            <span id="pad-left">{{ storageItemJob() }}</span>
          </div>
          <div id="pad" v-if="storageIsLead()">
            <v-icon small >mdi-phone</v-icon>
            <span id="pad-left">{{ storageItemPhone() }}</span>
          </div>
          <a id="backto" @click="$router.push('/company/workers')">
            <v-icon large>mdi-arrow-left-bold-circle</v-icon>
            <a id="pad-left">{{ $t("message.WorkList") }}</a>
          </a>
        </div>
      </v-card>
    </div>
  </div>
</template>

<style>
#about-text-work {
  display: flex;
  flex-direction: column;
  font: 13px/20px Ubuntu, Arial, sans-serif;
}
</style>

<script>
import CompanyDrawer from "../../../../views/Navigations/Company/CompanyDrawer";
export default {
  components: {
    CompanyDrawer,
  },
  data: () => ({
    items: [],
  }),
  methods: {
    storageId() {
      return localStorage.itemid;
    },
    // storageName1() {
    //   return localStorage.itemname1;
    // },
    storageItemName() {
      if (this.$i18n.locale === "ru") {
        return localStorage.itemname2;
      } else if (this.$i18n.locale === "gb") {
        return localStorage.itemname1;
      } else if (this.$i18n.locale === "de") {
        return localStorage.itemname3;
      }
    },
    storageImageUrl() {
      return "data:image/jpeg;base64," + localStorage.itemimage;
    },
    storageItemText() {
      if (this.$i18n.locale === "ru") {
        return localStorage.itemtext2;
      } else if (this.$i18n.locale === "gb") {
        return localStorage.itemtext1;
      } else if (this.$i18n.locale === "de") {
        return localStorage.itemtext3;
      }
    },
    storageItemJob() {
      if (this.$i18n.locale === "ru") {
        return localStorage.itemjob2;
      } else if (this.$i18n.locale === "gb") {
        return localStorage.itemjob1;
      } else if (this.$i18n.locale === "de") {
        return localStorage.itemjob3;
      }
    },
    storageItemEmail() {
      return localStorage.itememail;
    },
    storageItemPhone() {
      return localStorage.itemphone;
    },
    storageIsLead() {
      if (localStorage.itemislead === 'true') {
        return true;
      }
      else {
        return false
      }
    },
  },
};
</script>